import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import ComponentMap from "@/components/base/el/ComponentMap";
const BaseFilterComponent = () => import("@/components/base/filter/BaseFilterComponent")

export default {
    name: "BaseDropdownComponent",
    mixins: [BaseRenderMixin],
    methods: {
        get_el_props(element_data) {
            let el = element_data.tag
            let props = null
            if (element_data.attr) {
                if ('deepsel-filter' in element_data.attr) {
                    el = BaseFilterComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
            }


            if (Object.keys(ComponentMap).includes(element_data.tag)) {
                el = ComponentMap[element_data.tag]
                props = {component_data: element_data}
            }

            return [el, props]
        }
    }
};
